<template>
  <div class="grid">
    <div>
      <article>
        <header><strong>Connect to the Panel</strong></header>
        <div id="fb-ui-auth"></div>
      </article>
    </div>
  </div>
</template>

<script>

import { EmailAuthProvider, GoogleAuthProvider, GithubAuthProvider } from 'firebase/auth';
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import Vue from 'vue'

const auth = Vue.firebase.auth;
console.log(auth)

export default {
  name: 'Home',
  components: {
  },
  mounted() {
    let ui = new firebaseui.auth.AuthUI(auth);
    ui.start('#fb-ui-auth', {
      callbacks: {
        signInSuccessWithAuthResult: function() {
          return true;
        },
      },
      signInSuccessUrl: '/home',
      signInOptions: [
        GoogleAuthProvider.PROVIDER_ID,
        EmailAuthProvider.PROVIDER_ID,
        GithubAuthProvider.PROVIDER_ID
      ],
    });
  }
}
</script>

<style>
ul li.firebaseui-list-item {
  list-style: none;
}
</style>